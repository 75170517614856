<template>
    <Guest background>
        <div class="mb-10">
            <img class="h-8 w-auto mx-auto" src="@/assets/images/logo/logo-white.svg" alt="Scorelit logo">
        </div>
        <h1 class="text-center text-white font-extrabold italic text-xl">
            FOR THE <span class="text-3xl">PLAYERS</span> WHO<br/>
            WANT TO <span class="text-3xl">IMPROVE</span>.
        </h1>
        <div class="flex min-height-40 flex-col justify-end">
            <router-link :to="{ name: 'login'}" class="button-black w-full mb-4">{{ t('login') }}</router-link>
            <router-link :to="{ name: 'register'}" class="button w-full">{{ t('register') }}</router-link>
        </div>
    </Guest>
</template>

<script>
import Guest from "@/wrappers/Guest";
import useI18n from "@/modules/globalI18n";

export default {
    name: "Welcome",
    setup() {
        return useI18n({
            viewPrefix: "Welcome"
        });
    },
    components:{
        Guest
    },
}
</script>

<style scoped>
    .min-height-40 {
        min-height : 25vh;
    }
    @media (max-width: 640px) { 
        .min-height-40 {
            min-height : 45vh;
        }
    }
</style>